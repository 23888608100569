<div mat-dialog-title class="title-container">
  <h2>{{ 'building.assessment.title' | transloco }} ({{ 'building.buildingsCount' | transloco:{count} }})</h2>
</div>

<mat-dialog-content *ngIf="assessment$ | async as assessment; else loadingPreview">
  <sibat-assessment-report
    [building]="building$ | async"
    [assessment]="assessment"
    [hasCECBPolicy]="hasCECBPolicy$ | async"
    (closeModal)="close()">
  </sibat-assessment-report>
</mat-dialog-content>

<ng-template #loadingPreview> </ng-template>

<mat-dialog-actions align="end">
  <sibat-secondary-button
    class="mat-button-base"
    (clicked)="close()"
    [label]="'layout.closeDialog' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
