import { ROLE, Role } from './model/role.model';
import { Building } from '../building/model/building.model';
import { User } from './model/user.model';
import { Store } from '@ngrx/store';
import { selectHasPolicy } from './store/user.selector';

export const role = (r: Role) => {
  const isReadOnly = () => r === ROLE.readOnly;
  const isCECB = () => r === ROLE.cecb;
  const isSCPI = () => r === ROLE.scpi;
  const isSecretary = () => r === ROLE.secretary;
  const isExpert = () => r === ROLE.expert;
  const isAdmin = () => r === ROLE.admin;

  const isExpertOrAbove = () => isAdmin() || isExpert();
  const isEcabOrAbove = () => isExpertOrAbove() || isSecretary();
  const isSecretaryOrAbove = () => isExpertOrAbove() || isSecretary();
  const isSpecialistOrAbove = () => isExpertOrAbove() || isSCPI();
  const isSCPIOrAbove = () => isSecretaryOrAbove() || isSCPI();
  const isAccreditationBased = () => isSCPI() || isReadOnly();
  const isCECBOrAbove = () => isSpecialistOrAbove() || isCECB();

  return {
    /**
     * Asserts if the role can edit building typologies
     */
    isReadOnly,
    isCECB,
    isSCPI,
    isSecretary,
    isExpert,
    isAdmin,
    isExpertOrAbove,
    isEcabOrAbove,
    isSecretaryOrAbove,
    isSpecialistOrAbove,
    isSCPIOrAbove,
    isAccreditationBased,
    isCECBOrAbove
  };
};
export const authorization = (store, user?: User) => ({
  authorizedForBuilding: (building?: Building): boolean => {
    if (!building || !user) {
      return false;
    }

    let isAuthorized = false;

    store.select(selectHasPolicy('building.files.get')).subscribe((hasPolicy: boolean) => {
      isAuthorized =
        hasPolicy ||
        (
          user.activeRole === 'role_scpi' && user.roleContextValues.some(
            roleContext =>
              roleContext.roleCode === 'role_scpi' &&
              roleContext.contextValues.includes(building.municipalityFosNr)
        )) ||
        user.activeRole === 'role_cecb';
    }).unsubscribe();

    return isAuthorized;
  }
});
