import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { getBuilding } from './building.action';
import { selectHasPolicy } from '../../authentication/store/user.selector';
import { Store } from '@ngrx/store';
import { getInstallations } from './technical-installation.action';

@Injectable({
  providedIn: 'root',
})
export class BuildingTechnicalInstallationsEffects {
  // eslint-disable-next-line max-len
  hasTechnicalInstallationAnnouncementPolicy$: Observable<boolean> = this.store.select(selectHasPolicy('technicalInstallation.announcement.edit'));

  fetchBuildingTechnicalInstallationsEffect$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(getBuilding)),
      this.hasTechnicalInstallationAnnouncementPolicy$,
    ]).pipe(
      filter(([, ecabOrAbove]) => ecabOrAbove),
      map(([{ buildingId }]) => getInstallations({ buildingId }))
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}

