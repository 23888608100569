  <div *transloco="let t; read: 'building.assessment'" fxLayout="column">
    <div fxLayout="column">
<!--      <sibat-toggle-button-->
<!--          (toggle)="setOfflineMode($event)"-->
<!--          [checked]="forcedOffline$ | async"-->
<!--          [label]="t((forcedOffline$ | async) ? 'forcedOfflineModeEnabled' : 'forcedOfflineModeDisabled')"-->
<!--      >-->
<!--      </sibat-toggle-button>-->
      <div class="spaced-info">
        <p *ngIf="building" class="address">{{ building?.addresses[0] }}</p>
      </div>
      <sibat-assessment-status-alerts [alerts]="statusAlerts"></sibat-assessment-status-alerts>
      <mat-horizontal-stepper class="mat-horizontal-stepper-wrapper">
        <mat-step label="{{ t('steps.assessmentDetails') }}" state="enterAssessmentDetails">
          <div data-testid="step-information-section-assessment-details">
            <sibat-edit-assessment-details
                [assessment]="assessment"
                [readOnly]="readOnly"
                [offline]="offline"
                (unsavedDetails)="hasUnsavedDetails($event)"
                (assessmentDetailsSaved)="saveAssessmentDetails($event)"
            ></sibat-edit-assessment-details>
          </div>
        </mat-step>
        <mat-step label="{{ t('steps.buildingDetails') }}" state="enterBuildingDetails">
          <div data-testid="step-information-section-building-details">
            <sibat-edit-building-details
                [assessment]="assessment"
                [readOnly]="readOnly"
                [offline]="offline"
                (unsavedDetails)="hasUnsavedDetails($event)"
                (assessmentDetailsSaved)="saveAssessmentDetails($event)"
            ></sibat-edit-building-details>
          </div>
        </mat-step>
        <mat-step label="{{ t('steps.protectiveMeasures') }}" state="enterProtectiveMeasures">
          <div data-testid="step-information-section-protective-measures">
            <sibat-edit-protective-measures
                [buildingId]="buildingId"
                [assessment]="assessment"
                [readOnly]="readOnly"
                [offline]="offline"
                (unsavedDetails)="hasUnsavedDetails($event)"
                (assessmentDetailsSaved)="saveAssessmentDetails($event)"
            ></sibat-edit-protective-measures>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="container">

      <sibat-assessment-approval-button
          data-testid="approval-assessment"
          class="stretch-button"
          [isDisabled]="offline || !ongoingAssessmentCanBeValidated || unsavedDetails"
          [hasCECBPolicy]="hasCECBPolicy"
          [assessment]="assessment"
          (approveAssessment)="approveAssessment()"
          (requestApproval)="requestApproval()"
          (cancelRequestApproval)="cancelRequestApproval()"
      ></sibat-assessment-approval-button>
    </div>
    <div class="container">
      <sibat-secondary-button
          *ngIf="(hasAssessmentCancelPolicy$ | async)"
          (clicked)="requestOngoingAssessmentCancellation()"
          [label]="t('cancelOngoingAssessment')"
          [disabled]="offline || (isAssessmentOnApproval)"
          [stretch]="true"
          data-testid="cancel-assessment"
      >
      </sibat-secondary-button>
    </div>
  </div>
