import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectConfiguration, selectHasPolicy,
  selectIsGisTokenRegistered
} from 'src/app/authentication/store/user.selector';
import { removeTagFromBuilding } from '../store/tag.action';
import { selectBuilding, selectCurrentBuildingTags } from '../store/building.selector';
import { Observable } from 'rxjs';
import { Building } from '../model/building.model';

@Component({
  selector: 'sibat-building-overview',
  templateUrl: './building-overview.component.html',
  styleUrls: ['./building-overview.component.scss'],
})
export class BuildingOverviewComponent {
  hasPolicyCasePreviewDetails$ = this.store.select(selectHasPolicy('case.previewDetails.get'));
  building$: Observable<Building|undefined> = this.store.select(selectBuilding);
  tags$ = this.store.select(selectCurrentBuildingTags);
  gisTokenIsRegistered$ = this.store.select(selectIsGisTokenRegistered);
  configuration$ = this.store.select(selectConfiguration);

  constructor(private store: Store, private route: ActivatedRoute) {}

  deleteTag(buildingId: number, tagName: string) {
    this.store.dispatch(removeTagFromBuilding({ tagName, buildingId }));
  }
}
