import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Building } from '../model/building.model';
import { selectIsOffline } from '../../network/store/network.selector';
import { selectHasPolicy } from '../../authentication/store/user.selector';
import { Control, ControlDetails } from '../../control/model/control.model';
import { selectBuilding } from '../store/building.selector';
import { selectOngoingControl } from '../../control/store/control.selector';

@Component({
  selector: 'sibat-batch-control-edit-dialog',
  templateUrl: './batch-control-edit-dialog.component.html',
})
export class BatchControlEditDialogComponent {
  offline$ = this.store.select(selectIsOffline);
  hasControlBatchGetPolicy$ = this.store.select(selectHasPolicy('control.batch.get'));
  building$ = this.store.select(selectBuilding);
  control$ = this.store.select(selectOngoingControl);

  readonly building?: Building;
  readonly control?: Control;
  readonly count: number;

  constructor(
    private dialogRef: MatDialogRef<BatchControlEditDialogComponent, ControlDetails>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private data: { count: number }
  ) {
    this.count = this.data.count ?? 1;
  }

  close() {
    this.dialogRef.close();
  }
}
