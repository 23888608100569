import { Component } from '@angular/core';
import { User } from '../../authentication/model/user.model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUser } from '../../authentication/store/user.selector';
import MenuOption from '../model/menu-option';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sibat-user-widget',
  template: `
    <sibat-widget
      *ngIf="user$ | async as user"
      title="{{ user.firstName }} {{ user.lastName }}"
      subtitle="{{ user.email }}"
      [menu]="menu$ | async"
    >
      <mat-icon widget-icon>account_circle</mat-icon>
      <dl widget-content>
        <div>
          <dt>{{ 'cockpit.role' | transloco }}</dt>
          <dd>{{ 'cockpit.userRole' | transloco: { role: user.activeRole } }}</dd>
        </div>
      </dl>
    </sibat-widget>
  `,
  styleUrls: ['user-widget.component.scss'],
})
export class UserWidgetComponent {
  user$: Observable<User | undefined>;
  menu$: Observable<MenuOption[]>;

  constructor(private store: Store, private translocoService: TranslocoService) {
    this.user$ = this.store.select(selectUser);
    this.menu$ = this.translocoService
      .selectTranslate('cockpit.changePassword')
      .pipe(map(value => [{ route: '/changePassword', text: value }]));
  }
}
