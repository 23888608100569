import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectBuilding, selectCanHaveDocuments, selectCasePreviews } from '../store/building.selector';
import { CasePreview } from 'src/app/case/model/case.model';
import { TranslocoService } from '@ngneat/transloco';
import { selectHasPolicy } from '../../authentication/store/user.selector';

@Component({
  selector: 'sibat-building-documents',
  templateUrl: 'building-documents.component.html',
  styleUrls: ['building-documents.component.scss'],
})

export class BuildingDocumentsComponent {

  building$ = this.store.select(selectBuilding);
  canHaveDocuments$ = this.store.select(selectCanHaveDocuments);
  casesPreview$ = this.store.select(selectCasePreviews);
  hasPolicyCaseAccess$ = this.store.select(selectHasPolicy('case.access.get'));
  constructor(private store: Store, private translocoService: TranslocoService) {
  }

  //adapter pour les assessments
  getCaseTabLabel(casePreview: CasePreview): string {
    if (casePreview.friacFolderNumber !== null) {
      return casePreview.friacFolderNumber;
    }
    if (casePreview.type === 'CONTROL') {
      const controlLabel = this.translocoService.translate('building.control.steps.control');
      return `${controlLabel} ${this.formatDateLabel(casePreview.date)}`;
    } else if (casePreview.type === 'ASSESSMENT') {
      const assessmentLabel = this.translocoService.translate('building.assessment.type');
      return `${assessmentLabel} ${this.formatDateLabel(casePreview.date)}`;
    }
    return '';
  }

  private formatDateLabel(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

}
