import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { BuildingCaseService } from '../service/building-case.service';
import { fetchBuildingCase, fetchBuildingCaseFailure, fetchBuildingCaseSuccess } from './building-case.action';
import { combineLatest, of } from 'rxjs';
import { getBuilding } from './building.action';
import { selectHasPolicy } from '../../authentication/store/user.selector';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class BuildingCasesEffects {
  hasAccess$ = this.store.select(selectHasPolicy('case.access.get'));
  fetchBuildingCaseEffect$ = createEffect(() =>
    // eslint-disable-next-line ngrx/avoid-cyclic-effects
    combineLatest([this.actions$.pipe(ofType(getBuilding)), this.hasAccess$ ]).pipe(
      filter(([, hasAccess]) => hasAccess),
      map(([{ buildingId }]) => fetchBuildingCase({ buildingId }))
    )
  );

  refreshCasesInProgressEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchBuildingCase),
      mergeMap(({ buildingId }) =>
        this.casesService.getBuildingCases(buildingId).pipe(
          map(casePreviews => fetchBuildingCaseSuccess({ casePreviews })),
          catchError(error => of(fetchBuildingCaseFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private casesService: BuildingCaseService, private store: Store) {}
}
