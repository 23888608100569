<ng-container *ngIf="building$ | async as building">
  <div class="content-wrapper" *transloco="let t; read: 'building'">
    <div class="info-cards">
      <mat-card>
        <div class="card-header">
          <span>{{ t('summary.titleMO') }}</span>
          <span>
            <mat-icon>info</mat-icon>
          </span>
        </div>
        <mat-card-content>
          <sibat-building-info [building]="building" section="titleMO"></sibat-building-info>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <div class="card-header">
          <span>{{ t('summary.titleSibat') }}</span>
          <span>
            <mat-icon>info</mat-icon>
          </span>
        </div>
        <mat-card-content>
          <sibat-building-info [building]="building" section="titleSibat"></sibat-building-info>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <div class="card-header">
          <span>{{ t('list.addresses')}}</span>
          <span>
            <mat-icon>location_on</mat-icon>
          </span>
        </div>
        <mat-card-content class="card-paddings">
          <mat-list class="mat-list-no-padding">
            <mat-list-item class="address-item" *ngFor="let address of building.addresses">{{ address }}</mat-list-item>
          </mat-list>
          <div class="info">
            <p class="label">
              {{ 'building.summary.complementaryAdressInfo' | transloco }}
            </p>
          </div>
        </mat-card-content>
      </mat-card>
      <ng-container *ngIf="tags$ | async as tags">
        <mat-card>
          <div class="card-header">
            <span>{{ t('list.tags')}}</span>
            <span>
              <mat-icon>sell</mat-icon>
            </span>
          </div>
          <mat-card-content class="card-paddings">
            <mat-chip-list>
              <sibat-tag *ngFor="let tag of tags" [name]="tag" (delete)="deleteTag(building.id, tag)"></sibat-tag>
            </mat-chip-list>
            <sibat-add-tag [buildingIds]="[building.id]"></sibat-add-tag>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <div>
      </div>
      <mat-card *ngIf="building?.urlIcare || building?.urlCartoEcab || building?.urlDangersFR">
        <div class="card-header">
          <span>{{ t('detail.externalLinks')}}</span>
          <span>
            <mat-icon>link</mat-icon>
          </span>
        </div>
        <mat-card-content>
          <sibat-external-links [building]="building" [hasPolicyCasePreviewDetails]="hasPolicyCasePreviewDetails$ | async"></sibat-external-links>
        </mat-card-content>
      </mat-card>
      <div class="map-container">
        <sibat-mini-map content-header
                        [registeredGisToken]="gisTokenIsRegistered$ | async"
                        [configuration]="configuration$ | async"
                        [buildingId]="building.id"
                        [typology]="building.typology?.color"
        ></sibat-mini-map>
      </div>
    </div>
  </div>
</ng-container>
