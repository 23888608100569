<div *transloco="let t; read: 'building.technicalInstallation'">
  <section class="container">
    <div class="installation-actions">
      <sibat-primary-button [disabled]="installation.temporaryShutdownEndDate !== null || (hasTechnicalInstallationTogglePolicy$ | async) === false" [label]="t('temporaryShutdown')"
                            data-testid="shutdown-form-btn" (clicked)="requestUpdate(updateActions.temporaryShutdown)">
      </sibat-primary-button>
      <sibat-primary-button [disabled]="installation.temporaryShutdownEndDate === null || (hasTechnicalInstallationTogglePolicy$ | async) === false" [label]="t('endTemporaryShutdown')"
                            data-testid="shutdown-form-btn" (clicked)="requestUpdate(updateActions.endTemporaryShutdown)">
      </sibat-primary-button>
      <sibat-primary-button [disabled]="!!installation.shutdownDate || (hasTechnicalInstallationTogglePolicy$ | async) === false" [label]="t('shutdownOrDismantling')"
                            data-testid="shutdown-form-btn" (clicked)="requestUpdate(updateActions.shutdown)">
      </sibat-primary-button>
    </div>
    <div class="installation-actions">
      <sibat-primary-button [label]="t('periodicControl')"
        data-testid="periodic-control-form-btn" (clicked)="requestUpdate(updateActions.periodicControlReport)">
      </sibat-primary-button>
      <sibat-primary-button [label]="t('compliance')"
        data-testid="compliance-form-btn" (clicked)="requestUpdate(updateActions.complianceReport)">
      </sibat-primary-button>
      <sibat-primary-button
        [label]="t('evaluationOrRevision' , { type: installation.installationType })"
        data-testid="periodic-assessment-form-btn" (clicked)="requestUpdate(updateActions.assessmentReport)">
      </sibat-primary-button>
    </div>
    <div class="installation-actions" *ngIf="!installation.shutdownDate">
      <sibat-primary-button [label]="t('addInstallationCertificate')"
        data-testid="installation-certificate-btn" (clicked)="requestUpdate(updateActions.installationCertificate)">
      </sibat-primary-button>
      <sibat-primary-button
        [label]="t('addReceptionControlReport')" data-testid="reception-control-form-btn"
        (clicked)="requestUpdate(updateActions.receptionControlReport)">
      </sibat-primary-button>
      <sibat-primary-button [disabled]="installation.maintenanceFirm !== null"
        [label]="t('addMaintenanceContract')" data-testid="maintenance-form-btn"
        (clicked)="requestUpdate(updateActions.maintenanceContract)">
      </sibat-primary-button>
      <sibat-primary-button [disabled]="installation.maintenanceFirm === null" [label]="t('cancelMaintenanceContract')"
        data-testid="delete-maintenance-contact-btn" (clicked)="requestUpdate(updateActions.cancelMaintenanceContract)">
      </sibat-primary-button>
    </div>
    <div class="highlighted-details">
      <header>
        <h1>
          {{ t('summary') }}
        </h1>
      </header>

      <table class="installation-table">
        <tr class="header-row">
          <th data-testid="announcement-header" colspan="2">
            {{t('announcement')}}
          </th>
          <td class="action-column">
            <sibat-update-button [label]="t('edit')" icon="edit"
              (clicked)="requestUpdate(updateActions.announcement, false)"></sibat-update-button>
          </td>
        </tr>
        <tr>
          <td>{{ t('installationTypeLabel') }}</td>
          <td class="field-value">{{ t('installationType', {type: installation.installationType}) }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('designation') }}</td>
          <td class="field-value">{{ installation.designation ?? '-' }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('planner') }}</td>
          <td class="field-value">{{ installation.planner }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('installer') }}</td>
          <td class="field-value">{{ installation.installer }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('projectValidator') }}</td>
          <td class="field-value">{{ installation.validator }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('monitoringScope') }}</td>
          <td class="field-value">{{ t('monitoringScopeType', { type: installation.monitoringScope }) }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('requirementLabel') }}</td>
          <td class="field-value">{{ t('requirementType', { type: installation.necessity }) }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ t('ceaConnectedLabel') }}</td>
          <td class="field-value">{{ t('ceaConnectedType', { type: installation.ceaConnected }) }}</td>
          <td></td>
        </tr>
        <tr *ngIf="installation.transmitter">
          <td>{{ t('transmitter') }}</td>
          <td class="field-value">{{ installation.transmitter }}</td>
          <td></td>
        </tr>
        <ng-container *ngIf="installation.maintenanceFirm">
          <tr class="header-row">
            <th colspan="2" data-testid="maintenance-firm-header">
              {{t('maintenanceContract')}}
            </th>
            <td class="action-column">
              <sibat-update-button [label]="t('edit')" icon="edit"
                (clicked)="requestUpdate(updateActions.maintenanceContract, false)"></sibat-update-button>
            </td>
          </tr>
          <tr>
            <td>{{ t('maintenanceFirmName') }}</td>
            <td class="field-value">{{ installation.maintenanceFirm }}</td>
            <td></td>
          </tr>
        </ng-container>
        <ng-container *ngIf="installation.installationDate as installationDate">
          <tr class="header-row">
            <th colspan="2" data-testid="installation-certificate-header">
              {{t('installationCertificate')}}
            </th>
            <td class="action-column">
              <sibat-update-button [label]="t('edit')" icon="edit"
                (clicked)="requestUpdate(updateActions.installationCertificate, false)"></sibat-update-button>
            </td>
          </tr>
          <tr>
            <td>{{ t('installationDate') }}</td>
            <td class="field-value">{{ installationDate | date: 'dd.MM.yyyy' }}</td>
            <td></td>
          </tr>
          <tr>
            <td>{{ t('responsible') }}</td>
            <td class="field-value">{{ installation.responsible }}</td>
            <td></td>
          </tr>
          <tr class="header-row">
            <th colspan="2">
              {{ t('nextEvaluationOrRevision', { type: installation.installationType }) }}
            </th>
            <td class="action-column">
              <sibat-update-button [disabled]="!installation.assessmentFirm" [label]="t('edit')" icon="edit"
                (clicked)="requestUpdate(updateActions.assessmentReport, false)"></sibat-update-button>
            </td>
          </tr>
          <tr>
            <td>{{ t('date') }}</td>
            <td class="field-value">{{ installation.nextAssessmentYear }}</td>
            <td></td>
          </tr>
          <tr *ngIf="installation.assessmentFirm as assessmentFirm">
            <td>{{ t('assessmentFirm') }}</td>
            <td class="field-value">{{ assessmentFirm }}</td>
            <td></td>
          </tr>
        </ng-container>

        <ng-container *ngIf="installation.receptionControlDate as receptionControlDate">
          <tr class="header-row">
            <th colspan="3" data-testid="technical-controls-header">
              {{t('technicalControls')}}
            </th>
          </tr>
          <tr>
            <td>{{ t('receptionControlDate') }}</td>
            <td class="field-value">
              {{ receptionControlDate | date: 'dd.MM.yyyy' }}
              <span>({{ installation.controlBody }})</span>
            </td>
            <td class="action-column">
              <mat-icon class="action" [title]="t('edit')" (click)="requestUpdate(updateActions.receptionControlReport, false)">edit</mat-icon>
            </td>
          </tr>
          <tr *ngIf="installation.lastControlDate as lastControlDate">
            <td>{{ t('lastControlDate') }}</td>
            <td class="field-value">
              {{ lastControlDate | date: 'dd.MM.yyyy' }}
              <span *ngIf="installation.periodicController">({{ installation.periodicController }})</span>
            </td>
            <td class="action-column">
              <mat-icon class="action" [title]="t('edit')" (click)="requestUpdate(updateActions.periodicControlReport, false)">edit</mat-icon>
            </td>
          </tr>
          <tr *ngIf="!installation.maintenanceFirm && !installation.shutdownDate && installation.nextControlDate"
            [class.past]="pastNextControlDate">
            <td class="field-label">{{ t('nextControlDate') }}</td>
            <td class="field-value">{{ installation.nextControlDate | date: 'MM.yyyy' }}</td>
            <td class="icon-list">
              <mat-icon class="info" matTooltipClass="tooltip"
                [matTooltip]="t('nextControlDateInfo', { type: installation.installationType })">
                info
              </mat-icon>
              <mat-icon class="action" [title]="t('edit')" (click)="requestUpdate(updateActions.nextControlDate)">edit</mat-icon>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="installation.shutdownDate as shutdownDate">
          <tr class="header-row">
            <th colspan="2" data-testid="shutdown-header">
              {{t('shutdownOrDismantling')}}
            </th>
            <td class="action-column">
              <sibat-update-button [disabled]="(hasTechnicalInstallationTogglePolicy$ | async) === false" [label]="t('edit')" icon="edit"
                                   (clicked)="requestUpdate(updateActions.shutdown, false)"></sibat-update-button>
            </td>
          </tr>
          <tr>
            <td>{{ t('shutdownDate') }}</td>
            <td class="field-value" colspan="2">{{ shutdownDate | date: 'dd.MM.yyyy' }}</td>
          </tr>
          <tr>
            <td>{{ t('shutdownReason') }}</td>
            <td class="field-value" colspan="2">{{ installation.shutdownReason ?? '-' }}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </section>
</div>
