import { CONTROL_STATUS, controlData, HistoryEntry, typologyData } from '../model/history.model';
import { createSelector } from '@ngrx/store';
import BuildingState from './building.reducer';
import { State } from '../../reducers';
import { BuildingDocumentBrowsingData } from '../model/building-document.model';
import { selectIsBuildingDocumentBrowsingEnabled } from 'src/app/features/store/feature-flags.selector';
import { selectHasPolicy } from 'src/app/authentication/store/user.selector';

const SCPI_AUTHORISED_FOLDERS = [
  '04_Controles',
];

export const getBuildingState = (state: State) => state.building;

export const selectBuilding = createSelector(getBuildingState, (state: BuildingState) => state.building);
export const selectBuildingLoading = createSelector(getBuildingState, (state: BuildingState) => state.buildingLoading);

export const selectCurrentBuildingTags = createSelector(selectBuilding, building => building?.tags);
export const selectCurrentBuildingId = createSelector(selectBuilding, state => state?.id);

export const selectBuildingAddresses = createSelector(selectBuilding, building => building?.addresses ?? []);

export const selectHistoryTimeline = createSelector(getBuildingState, (state: BuildingState): Map<string, HistoryEntry[]> => {
  const history = state.history;
  if (!history) {
    return new Map<string, HistoryEntry[]>();
  }
  const yearLabel = (date: Date) => `${date.getFullYear()}`;
  const controlsHistory = history?.control
    .filter(it => it.status === CONTROL_STATUS.completed)
    .map(it => {
      if (it.reportFileName) {
        return { ...it, user: undefined };
      }
      return it;
    })
    .map(controlData);
  const typologiesHistory = history?.typology.map((it, i) => {
    const previousColor = history.typology[i - 1]?.color;
    return typologyData(it, previousColor);
  });
  const timeline = typologiesHistory?.concat(controlsHistory).sort((a, b) => b.date.localeCompare(a.date));
  return timeline?.reduce((acc, curr) => {
    const year = yearLabel(new Date(curr.date));
    const accYearValue = acc.has(year) && acc.get(year);
    if (accYearValue) {
      acc.set(year, [...accYearValue, curr]);
    } else {
      acc.set(year, [curr]);
    }
    return acc;
  }, new Map<string, HistoryEntry[]>());
});

export const selectBuildingDocumentBrowsingData = createSelector(
  getBuildingState,
  (state: BuildingState): BuildingDocumentBrowsingData => ({
    buildingId: state.building?.id ?? 0,
    documentsPath: state.buildingDocumentsPath,
  })
);

export const selectBuildingSharePointUrl = createSelector(
  selectBuilding,
  (building): string | undefined => building?.urlSharePoint
);

export const selectBuildingSharePointDynamicUrl = createSelector(
  selectBuildingDocumentBrowsingData,
  selectBuildingSharePointUrl,
  (browsingData: BuildingDocumentBrowsingData, sharePointUrl): string | undefined => {
    if (sharePointUrl) {
      return `${sharePointUrl}/${browsingData.documentsPath}`;
    }
    return undefined;
  }
);

export const selectIsBrowsingRootFolder = createSelector(
  getBuildingState,
  (state: BuildingState): boolean => state.buildingDocumentsPath === ''
);

export const selectIsEditableFolder = createSelector(
  getBuildingState,
  selectHasPolicy('sharepoint.file.managementecab'),
  selectHasPolicy('sharepoint.control.add'),
  (buildingState: BuildingState, hasFileManagementPolicy: boolean, hasControlAddPolicy: boolean): boolean =>
    (
      (
        SCPI_AUTHORISED_FOLDERS.some(folder => buildingState.buildingDocumentsPath.startsWith(folder))
        && hasControlAddPolicy
      )
      || hasFileManagementPolicy
    )
);

export const selectBuildingDocuments = createSelector(
  getBuildingState,
  selectIsBuildingDocumentBrowsingEnabled,
  (state: BuildingState, isBrowsingEnabled) =>
    isBrowsingEnabled
      ? state.buildingDocuments.slice().sort((a, b) => {
          if (a.isFolder === b.isFolder) {
            return a.name.localeCompare(b.name);
          } else {
            return a.isFolder ? -1 : 1;
          }
        })
      : state.buildingDocuments
          .filter(document => !document.isFolder)
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
);

export const selectCasePreviews = createSelector(getBuildingState, (state: BuildingState) => state.casePreviews);

export const selectCanHaveDocuments = createSelector(selectBuilding, building => !!building?.status);

export const selectShouldDisplayDocuments = createSelector(selectBuilding, selectCasePreviews, (building, cases) =>
  !!building?.status || !!cases?.length);


export const selectTechnicalInstallation = createSelector(getBuildingState, (state: BuildingState) => state.technicalInstallationDetail);

export const selectTechnicalInstallations = createSelector(getBuildingState, (state: BuildingState) => state.technicalInstallationList);

export const selectDeleteInfos = createSelector(getBuildingState, (state: BuildingState) => state.deleteInfos);
