import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { APPROVAL_STATE, ApprovalState } from '../../model/approval-state.model';
import { approvalAssessmentStatus, AssessmentDto } from '../../model/assessment.model';

@Component({
  selector: 'sibat-assessment-approval-button',
  template: `
    <section *transloco="let t; read: 'building.assessment'" fxLayout="column">
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.expertShouldApproveAssessment"
        (clicked)="triggerApproveAssessment()"
        [label]="t('approveAssessment')"
        [disabled]="isDisabled"
        [stretch]="true"
      >
      </sibat-primary-button>
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.cecbRequireExpertApproval"
        (clicked)="triggerRequestApproval()"
        [label]="t('validateAssessment')"
        [disabled]="isDisabled"
        [stretch]="true"
      >
      </sibat-primary-button>
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.expertShouldApproveAssessment"
        (clicked)="triggerCancelRequestApproval()"
        [label]="t('cancelRequestExpertApproval')"
        [disabled]="isDisabled"
        [stretch]="true"
        class="last-button"
      >
      </sibat-primary-button>
    </section>
  `,
  styles: ['.last-button { margin-top: 0.5rem }'],
})
export class AssessmentApprovalButtonComponent implements OnChanges {
  //@Output() validateAssessment = new EventEmitter<void>();
  @Output() requestApproval = new EventEmitter<void>();
  @Output() cancelRequestApproval = new EventEmitter<void>();
  @Output() approveAssessment = new EventEmitter<void>();
  @Input() assessment: AssessmentDto;
  @Input() isDisabled: boolean;
  @Input() hasCECBPolicy: boolean;
  approvalStates = APPROVAL_STATE;
  approvalState?: ApprovalState;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assessment) {
      this.updateApprovalState();
    }
  }

  triggerRequestApproval() {
    this.requestApproval.emit();
  }

  triggerCancelRequestApproval() {
    this.cancelRequestApproval.emit();
  }

  triggerApproveAssessment() {
    this.approveAssessment.emit();
  }

  updateApprovalState() {
    this.approvalState = !!this.assessment ? approvalAssessmentStatus(this.assessment, this.hasCECBPolicy) : undefined;
  }
}
