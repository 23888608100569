import { Component, OnDestroy, OnInit } from '@angular/core';
import { ROLE, RoleFullDto } from '../../../authentication/model/role.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserUpdate } from '../../../admin/model/user-creation.model';
import { updateUser } from '../../../admin/store/admin.action';
import { ActivatedRoute } from '@angular/router';
import { RolesUser, User } from 'src/app/authentication/model/user.model';
import { selectUser } from 'src/app/authentication/store/user.selector';
import { role } from 'src/app/authentication/capability';
import { selectAllRoles } from '../../../admin/store/admin.selector';
import { getContextCode, isRoleSelected, toggleRole } from '../user-tools';
import { UserAdminService } from '../../../admin/service/user-admin.service';
import { map, shareReplay } from 'rxjs/operators';
import { ContextValues } from '../../../admin/model/context-values.model';

@Component({
  selector: 'sibat-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  rolesList = Object.values(ROLE);
  userForm: UntypedFormGroup;
  user?: User;
  role = new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required], updateOn: 'change' });
  roles: UntypedFormControl;
  isAccreditationBased = false;
  roles$: Observable<RoleFullDto[]>;
  contextValuesCache$: { [key: string]: Observable<ContextValues[]> } = {};
  formFields = [
    {label: 'lastName', name: 'lastName', type: 'input', errorMessage: 'errors.requiredField'},
    {label: 'firstName', name: 'firstName', type: 'input', errorMessage: 'errors.requiredField'},
    {label: 'email', name: 'email', type: 'input', errorMessage: 'requiredValidEmail'},
    {label: 'phoneNumber', name: 'phoneNumber', type: 'input'},
    {
      label: 'language',
      name: 'language',
      type: 'select',
      options: [
        {value: 'fr', label: 'french'},
        {value: 'de', label: 'german'}
      ],
      errorMessage: 'errors.requiredField'
    }
  ];

  private subscription = new Subscription();

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userAdminService: UserAdminService
  ) {
    this.roles = new UntypedFormControl({value: [], disabled: true}, {
      validators: [Validators.required],
      updateOn: 'change'
    });
  }

  get roleContextValues(): UntypedFormArray {
    return this.userForm.get('roleContextValues') as UntypedFormArray;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.roles$ = this.store.select(selectAllRoles);
    const enabled = new UntypedFormControl({value: '', disabled: true}, Validators.required);
    this.userForm = this.formBuilder.group({
      lastName: ['', {validators: [Validators.required], updateOn: 'change'}],
      firstName: ['', {validators: [Validators.required], updateOn: 'change'}],
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        },
      ],
      phoneNumber: ['', {updateOn: 'change'}],
      role: this.role.value,
      roles: this.roles.value,
      language: ['', {validators: [Validators.required], updateOn: 'change'}],
      enabled,
      roleContextValues: this.formBuilder.array([])
    });

    this.subscription.add(
      this.route.data.subscribe(data => {
        this.user = data.user;
        this.userForm.patchValue({
          lastName: this.user?.lastName,
          firstName: this.user?.firstName,
          email: this.user?.email,
          phoneNumber: this.user?.phoneNumber,
          role: this.user?.role,
          roles: this.user?.roles,
          language: this.user?.language,
          enabled: this.user?.enabled,
        });

        if (this.user?.roles) {
          this.initRoleContexts(this.user.roles);
        }
      })
    );

    this.subscription.add(
      this.store.select(selectUser).subscribe(user => {
        if (user !== undefined && user.id === this.user?.id) {
          enabled.disable();
          this.roles.disable();
        } else {
          enabled.enable();
          this.roles.enable();
        }
      })
    );

    this.subscription.add(
      this.store.select(selectUser).subscribe(user => {
        if (user !== undefined && user.id === this.user?.id) {
          enabled.disable();
          this.role.disable();
        } else {
          enabled.enable();
          this.role.enable();
        }
      })
    );
  }

  isRoleSelected(selectedRole: string): boolean {
    return isRoleSelected(this.userForm, selectedRole);
  }

  getContextCodeForRole(selectedRole: string): string | null {
    let contextLabel: string | null = null;
    this.roles$.subscribe(roles => {
      contextLabel = getContextCode(roles, selectedRole);
    });
    return contextLabel;
  }

  getLabelForRole(selectedRole: {name: string ; code: string}): string | null {
    return selectedRole.name;
  }

  getContextValuesForSelectedRole(roleCode: {name: string ; code: string}): Observable<ContextValues[]> {
    const contextCode = this.getContextCodeForRole(roleCode.code);

    if (!this.contextValuesCache$[roleCode.code]) {
      if (!contextCode) {
        this.contextValuesCache$[roleCode.code] = of([]);
      } else {
        this.contextValuesCache$[roleCode.code] = this.userAdminService.fetchContextValues(roleCode.code).pipe(
          map(contextValues => contextValues.sort((a, b) => a.geoFieldAlias.localeCompare(b.geoFieldAlias))),
          shareReplay(1),
        );
      }
    }
    return this.contextValuesCache$[roleCode.code];
  }

  toggleRole(changedRole: string): void {
    toggleRole(this.userForm, changedRole, this.roles$);
    this.initRoleContexts(this.userForm.get('roles')?.value);
  }

  onSubmit(): void {
    if (!this.user) {
      return;
    }

    const rolesValue = this.userForm.get('roles')?.value || [];

    const user: UserUpdate = {
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      phoneNumber: this.userForm.get('phoneNumber')?.value,
      role: this.userForm.get('role')?.value,
      language: this.userForm.get('language')?.value,
      id: this.user.id,
      enabled: this.userForm.get('enabled')?.value,
      roles: rolesValue,
      roleContextValues: this.cleanUpRoleContextValues(),
    };

    this.store.dispatch(updateUser({user}));
  }

  private cleanUpRoleContextValues() {
    const selectedRoles = (this.userForm.get('roles')?.value || []).map((x)=>x.code);
    const val = this.roleContextValues.value.map(({roleCode, contextValues}): any=> ({roleCode:roleCode.code, contextValues}));
    return val.filter(({ roleCode, contextValues }) => selectedRoles.includes(roleCode) && contextValues.length > 0

    );
  }

  private initRoleContexts(roles: RolesUser[] | undefined): void {
    const contextValuesArray = this.userForm.get('roleContextValues') as UntypedFormArray;
    contextValuesArray.clear();

    if (!roles) {
      return;
    }

    roles.forEach(r => {
      const existingContext = this.user?.roleContextValues?.find(context => context.roleCode === r.code);
      const existingContextValues = existingContext ? existingContext.contextValues.map(x => x.toString()) : [];
      const contextValueControl = this.formBuilder.group({
        roleCode: r,
        contextValues: this.formBuilder.control(existingContextValues, Validators.required),
      });

      contextValuesArray.push(contextValueControl);
    });
  }
}
