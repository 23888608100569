import { APPROVAL_STATE } from './approval-state.model';
import { User } from '../../authentication/model/user.model';
import { ASSESSMENT_STATUS, AssessmentStatus } from '../../building/model/history.model';

//
// interface AssessmentBase {
//   id: number;
//   date: string;
//   user: User;
//   status: AssessmentStatus;
//   landlordOrOperator: string | null;
//   remarks: string | null;
//   peoplePresent: string | null;
//   approverId: number;
// }
//
// export interface AssessmentDetails extends AssessmentBase {
//   approvalRequestDate?: string;
//   approvalDate?: string;
//   designation?: string;
//   reportFileName?: string;
//   caseId?: number;
//   buildingId: number;
// }
//
// export interface Assessment extends AssessmentDetails {
//   affectations?: number[];
//   flatNumber?: number;
//   stairwellNumber?: number;
//   abovegroundLevelNumber?: number;
//   undergroundLevelNumber?: number;
//   baseground?: number[];
//   facadesArea?: number;
//   roofArea?: number;
//   awningArea?: number;
//   awningNumber?: number;
//   awnings?: number[];
//   automation?: boolean;
//   exteriorDoorNumber?: number;
//   parkingEntranceNumber?: number;
//   stepOverNumber?: number;
//   stairsNumber?: number;
//   pcShelterNumber?: number;
//   domeNumber?: number;
//   ventilationDuctNumber?: number;
//   skylightNumber?: number;
// }

export interface AssessmentBase {
  id: number;
  date?: string;
  user?: User;
  status: AssessmentStatus;
  landlordOrOperator?: string | null;
  remarks?: string | null;
  peoplePresent?: string | null;
  designation?: string;
}

export interface AssessmentDto extends AssessmentBase {
  approverId?: number;
  approvalRequestDate?: string;
  approvalDate?: string;
  reportFileName?: string;
  caseId?: number;
  buildingId?: number;
  affectations?: number[];
  flatNumber?: number;
  stairwellNumber?: number;
  abovegroundLevelNumber?: number;
  undergroundLevelNumber?: number;
  baseground?: number[];
  facadesArea?: number;
  roofArea?: number;
  awningArea?: number;
  awningNumber?: number;
  awnings?: number[];
  automation?: boolean;
  exteriorDoorNumber?: number;
  parkingEntranceNumber?: number;
  stepOverNumber?: number;
  stairsNumber?: number;
  pcShelterNumber?: number;
  domeNumber?: number;
  glassDomeNumber?: number;
  ventilationDuctNumber?: number;
  skylightNumber?: number;
  protectiveMeasures: EditProtectiveMeasure[];
  remarksMeasures?: string;
  stairwellPictures?: EditPicture[];
  facadePictures?: EditPicture[];
  buildingSituationPictures?: EditPicture[];
  stairwellThumbnails?: AssessmentThumbnail[];
  facadeThumbnails?: AssessmentThumbnail[];
  buildingSituationThumbnails?: AssessmentThumbnail[];
}

export interface AssessmentBuildingDetailsDto {
  affectations?: number[];
  flatNumber?: number;
  stairwellNumber?: number;
  abovegroundLevelNumber?: number;
  undergroundLevelNumber?: number;
  baseground?: number[];
  facadesArea?: number;
  roofArea?: number;
  awningArea?: number;
  awningNumber?: number;
  awnings?: number[];
  automation?: boolean;
  exteriorDoorNumber?: number;
  parkingEntranceNumber?: number;
  stepOverNumber?: number;
  stairsNumber?: number;
  pcShelterNumber?: number;
  domeNumber?: number;
  ventilationDuctNumber?: number;
  skylightNumber?: number;
  stairwellPictures: EditPicture[];
  facadePictures: EditPicture[];
  buildingSituationPictures: EditPicture[];
  stairwellThumbnails: AssessmentThumbnail[];
  facadeThumbnails: AssessmentThumbnail[];
  buildingSituationThumbnails: AssessmentThumbnail[];
}

export interface MeasuresInfo {
  remarksMeasures?: string;
}

export interface RefAwning {
  id: number;
  text: string;
}
export interface RefAffectation {
  id: number;
  text: string;
}

export interface RefBaseground {
  id: number;
  text: string;
}

export interface EditProtectiveMeasure {
  id?: number;
  type?: number;
  description?: string;
  proposition?: string;
}

export interface MeasureType {
  id: number;
  text: string;
}

export interface AssessmentReportInfo {
  fileName: string;
  relativePath: string;
  buildingId: number;
}

export const newEditMeasure = (): EditProtectiveMeasure => ({
  description: '',
  proposition: ''
});

export interface AddPicture {
  id?: number;
  pictures: FileWithPreviewBase64[];
}

export interface EditPicture {
  picturesToAdd: FileWithPreviewBase64[];
  existingPictures: EditPictureBase64[];
  picturesToRemove: string[];
}

export interface EditPictureBase64 {
  name: string;
  file?: FileWithPreviewBase64;
}

export interface FileWithPreviewBase64 {
  data: string;
  fileName: string;
  preview?: string;
}

export interface AssessmentThumbnail {
  pictureName: string;
  thumbnail: string;
}

export interface AssessmentPicture {
  pictureFileName: string;
  pictureData: string;
}

export const newEditPicture = (): EditPicture => ({
  picturesToAdd: [],
  existingPictures: [],
  picturesToRemove: [],
});

export const canAssessmentBeValidated = (assessment: AssessmentDto): boolean =>
  Boolean(assessment.date) &&
  Boolean(assessment.landlordOrOperator) &&
  Boolean(assessment.peoplePresent) &&
  assessment.flatNumber != null &&
  assessment.stairwellNumber != null &&
  assessment.abovegroundLevelNumber != null &&
  assessment.undergroundLevelNumber != null &&
  assessment.facadesArea != null &&
  assessment.roofArea != null &&
  assessment.awningArea != null &&
  assessment.awningNumber != null &&
  assessment.exteriorDoorNumber != null &&
  assessment.parkingEntranceNumber != null &&
  assessment.stepOverNumber != null &&
  assessment.stairsNumber != null &&
  assessment.pcShelterNumber != null &&
  assessment.domeNumber != null &&
  assessment.glassDomeNumber != null &&
  assessment.ventilationDuctNumber != null &&
  assessment.skylightNumber != null;


export const approvalAssessmentStatus = (assessment: AssessmentDto, hasCECBPolicy: boolean) => {
  if (isAssessmentCompleted(assessment)) {
    return APPROVAL_STATE.assessmentValidated;
  }

  if (hasCECBPolicy) {
    if (needsAssessmentApproval(assessment)) {
      return APPROVAL_STATE.cecbRequireExpertApproval;
    }

    if (isAwaitingForApprovalAssessment(assessment)) {
      return APPROVAL_STATE.cecbAwaitingExpertApproval;
    }

    if (isAssessmentApproved(assessment)) {
      return APPROVAL_STATE.expertHasApprovedAssessment;
    }
  }

  if (!hasCECBPolicy) {
    if (needsAssessmentApproval(assessment)) {
      return APPROVAL_STATE.assessmentCanBeValidated;
    }

    if (isAwaitingForApprovalAssessment(assessment)) {
      return APPROVAL_STATE.expertShouldApproveAssessment;
    }

    if (isAssessmentApproved(assessment)) {
      return APPROVAL_STATE.assessmentCanBeValidated;
    }
  }
};

export const needsAssessmentApproval = (assessment: AssessmentDto): boolean =>
  assessment.status === ASSESSMENT_STATUS.ongoing;

export const isAwaitingForApprovalAssessment = (assessment: AssessmentDto): boolean =>
  assessment.status === ASSESSMENT_STATUS.approval;

export const isAssessmentApproved = (assessment: AssessmentDto): boolean =>
  assessment.status === ASSESSMENT_STATUS.approved;


export const isAssessmentCompleted = (assessment: AssessmentDto): boolean =>
  assessment.status === ASSESSMENT_STATUS.approved;


export const isAssessmentEmpty = (assessment: AssessmentDto): boolean =>
  !assessment.designation &&
  !assessment.landlordOrOperator &&
  !assessment.peoplePresent &&
  !assessment.remarks;

export type AbstractAssessmentDetail = {
  [P in keyof AssessmentDto]: any;
};
