import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { authorization } from 'src/app/authentication/capability';
import { selectHasPolicy, selectUser } from 'src/app/authentication/store/user.selector';
import {
  fetchControl,
  refreshOngoingControl,
  refreshOrphanControls,
  startNewControl
} from 'src/app/control/store/control.action';
import { selectOngoingControl, selectOrphanControls } from 'src/app/control/store/control.selector';
import {
  selectIsBuildingDocumentBrowsingEnabled,
  selectIsControlEnabled,
  selectIsItechEnabled,
} from 'src/app/features/store/feature-flags.selector';
import { createTextTranslation } from 'src/app/shared/localization';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FabButton, FabService } from 'src/app/shared/service/fab.service';
import { ControlDefectListComponent } from '../control-defect-list/control-defect-list.component';
import { Building, BUILDING_STATUS, BuildingSummary } from '../model/building.model';
import { AnnouncementForm } from '../model/technical-installations.model';
import {
  selectBuilding,
  selectBuildingLoading,
  selectCasePreviews, selectShouldDisplayDocuments, selectTechnicalInstallations
} from '../store/building.selector';
import { selectSelectedBuildings } from '../store/buildings.selector';
import { selectAffectations } from '../store/reference-data.selector';
import { announceInstallation } from '../store/technical-installation.action';
import { AnnouncementFormDialogComponent } from '../technical-installations/announcement-form-dialog/announcement-form-dialog.component';
import { LinkControlToBuildingComponent } from '../link-control-to-building/link-control-to-building.component';
import { BatchTypologyEditDialogComponent } from '../batch-typology-edit-dialog/batch-typology-edit-dialog.component';
import { UpdateTypologyDto } from '../model/typology.dto';
import { clearCurrentBuilding, updateTypologies } from '../store/building.action';
import { BatchControlEditDialogComponent } from '../batch-control-edit-dialog/batch-control-edit-dialog.component';
import { ControlDetails } from '../../control/model/control.model';
import { refreshOngoingAssessment, startNewAssessment } from 'src/app/assessment/store/assessment.action';
import { BatchAssessmentEditDialogComponent } from '../batch-assessment-edit-dialog/batch-assessment-edit-dialog.component';
import {
  selectOngoingAssessment,
  selectCanEditOngoingAssessment
} from '../../assessment/store/assessment.selector';
import { AssessmentDto } from '../../assessment/model/assessment.model';

@Component({
  selector: 'sibat-building-page',
  templateUrl: './building-page.component.html',
  styleUrls: ['./building-page.component.scss'],
})
export class BuildingPageComponent implements OnDestroy, OnInit {
  buildingId$: Observable<number> = this.route.params.pipe(map(params => Number(params.buildingId)));
  building$: Observable<Building | undefined> = this.store.select(selectBuilding);
  loading$ = this.store.select(selectBuildingLoading);
  user$ = this.store.select(selectUser);
  affectations$ = this.store.select(selectAffectations);
  ongoingControl$ = this.store.select(selectOngoingControl);
  ongoingAssessment$ = this.store.select(selectOngoingAssessment);
  cases$ = this.store.select(selectCasePreviews);
  itechs$ = this.store.select(selectTechnicalInstallations);
  orphanControls$ = this.store.select(selectOrphanControls);
  hasBuildingOverviewPolicy$ = this.store.select(selectHasPolicy('building.overview.get'));
  hasBuildingDocumentPolicy$ = this.store.select(selectHasPolicy('building.document.get'));
  isControlEnabled$ = this.store.select(selectIsControlEnabled);
  isItechEnabled$ = this.store.select(selectIsItechEnabled);
  canEditOngoingAssessment$ = this.store.select(selectCanEditOngoingAssessment);
  canEditTypology$ = this.store.select(selectHasPolicy('fab.typology.get'));
  canEditControl$ = this.store.select(selectHasPolicy('fab.control.get'));
  canGetAssessment$ = this.store.select(selectHasPolicy('fab.assessment.get'));
  canControlAssessment$ = this.store.select(selectHasPolicy('fab.controlAssessment.get'));
  canAnnounceTechnicalInstallation$ = this.store.select(selectHasPolicy('fab.technicalInstallation.get'));
  canBrowseDocuments$ = combineLatest([
    this.store.select(selectShouldDisplayDocuments),
    this.store.select(selectIsBuildingDocumentBrowsingEnabled),
  ]).pipe(map(([canHaveDocuments, browsingEnabled]) => canHaveDocuments && browsingEnabled));
  selectedBuildingsCount: number;
  selectedBuildings: BuildingSummary[];
  buildingsSubscriber = this.store.select(selectSelectedBuildings).subscribe(buildings => {
    this.selectedBuildingsCount = buildings.length;
    this.selectedBuildings = buildings;
  });

  authorizedForBuilding: boolean;

  readonly buildingStatus = BUILDING_STATUS;

  readonly startControlButton = new FabButton({
    label: createTextTranslation('building.startControl'),
    action: () => this.startControl(),
  });

  readonly editControlButton = new FabButton({
    label: createTextTranslation('building.modifyControl'),
    action: () => this.editControl(),
  });

  readonly announceTechnicalInstallationButton = new FabButton({
    label: createTextTranslation('building.announceTechnicalInstallation'),
    action: () => this.announceTechnicalInstallation(),
  });

  readonly linkControlToBuildingButton = new FabButton({
    label: createTextTranslation('building.linkControlToBuilding'),
    action: () => this.selectLinkControlToBuildingModal(),
  });

  readonly editTypologyButton = new FabButton({
    label: createTextTranslation('building.typology.edit'),
    action: () => this.editTypology(),
  });

  readonly startAssessmentButton = new FabButton({
    label: createTextTranslation('building.startAssessment'),
    action: () => this.startAssessment(),
  });

  readonly editAssessmentButton = new FabButton({
    label: createTextTranslation('building.modifyAssessment'),
    action: () => this.editAssessment(),
  });

  readonly editAssessmentButtonDisabled = new FabButton({
    label: createTextTranslation('building.modifyAssessmentDisabled'),
    action: () => {
    },
    disabled: true,
  });

  readonly controlAssessmentButton = new FabButton({
    label: createTextTranslation('building.controlAssessment'),
    action: () => this.editAssessment(),
  });

  private readonly subscriptions = new Subscription();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private fabService: FabService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(params => {
        if (params['compliance']) {
          const controlId = params['compliance'];
          this.startEditingDefect(controlId);
        }
      })
    );

    this.subscriptions.add(
      combineLatest([this.building$, this.user$])
        .pipe(map(([building, user]) => authorization(this.store, user).authorizedForBuilding(building)))
        .subscribe(authorized => {
          this.authorizedForBuilding = authorized;
        })
    );

    this.subscriptions.add(
      combineLatest([
        this.user$,
        this.building$,
        this.ongoingControl$,
        this.ongoingAssessment$,
        this.orphanControls$,
        this.canAnnounceTechnicalInstallation$,
        this.affectations$,
        this.canControlAssessment$,
        this.isControlEnabled$,
        this.isItechEnabled$,
        this.canGetAssessment$,
        this.canEditOngoingAssessment$,
        this.canEditTypology$,
        this.canEditControl$
      ])
        .pipe(
          map((
            [
              user,
              building,
              ongoingControl,
              ongoingAssessment,
              orphanControls,
              canAnnounceTechnicalInstallation,
              affectations,
              canControlAssessment,
              controlEnabled,
              itechEnabled,
              canGetAssessment,
              canEditOngoingAssessment,
              canEditTypology,
              canEditControl
            ]) => ({
              authorizedForBuilding: authorization(this.store, user).authorizedForBuilding(building),
              hasOngoingControl: !!building?.ongoingControl || !!ongoingControl,
              hasOngoingAssessment: !!building?.ongoingAssessment || !!ongoingAssessment,
              hasOrphanControls: orphanControls.length > 0,
              canAnnounceTechnicalInstallation,
              hasAffectations: affectations.length > 0,
              canControlAssessment,
              controlEnabled,
              itechEnabled,
              canGetAssessment,
              canEditOngoingAssessment,
              canEditTypology,
              canEditControl
            }))
        )
        .subscribe((
          {
            authorizedForBuilding,
            hasOngoingControl,
            hasOngoingAssessment,
            hasOrphanControls,
            canAnnounceTechnicalInstallation,
            hasAffectations,
            canControlAssessment,
            controlEnabled,
            itechEnabled,
            canGetAssessment,
            canEditOngoingAssessment,
            canEditTypology,
            canEditControl }) => {
          this.updateFab(
            authorizedForBuilding,
            hasOngoingControl,
            hasOngoingAssessment,
            hasOrphanControls,
            canAnnounceTechnicalInstallation,
            hasAffectations,
            canControlAssessment,
            controlEnabled,
            itechEnabled,
            canGetAssessment,
            canEditOngoingAssessment,
            canEditTypology,
            canEditControl
          );
        })
    );

    this.store.dispatch(refreshOrphanControls());
  }

  ngOnDestroy(): void {
    this.buildingsSubscriber.unsubscribe();
    this.subscriptions.unsubscribe();
    this.fabService.clearFabOptions();
    this.store.dispatch(clearCurrentBuilding());
  }

  updateFab = (
    authorizedForBuilding: boolean,
    hasOngoingControl: boolean,
    hasOngoingAssessment: boolean,
    hasOrphanControls: boolean,
    canAnnounceTechnicalInstallation: boolean,
    hasAffectations: boolean,
    canControlAssessment: boolean,
    controlEnabled: boolean,
    itechEnabled: boolean,
    canGetAssessment: boolean,
    canEditOngoingAssessment: boolean,
    canEditTypology: boolean,
    canEditControl: boolean
  ) => {
    const buttons: FabButton[] = [];

    if (authorizedForBuilding) {
      if (controlEnabled && canEditControl) {
        if (hasOngoingControl) {
          buttons.push(this.editControlButton);
        } else {
          buttons.push(this.startControlButton);
          if (hasOrphanControls) {
            buttons.push(this.linkControlToBuildingButton);
          }
        }
      }

      if (canGetAssessment) {
        if (hasOngoingAssessment) {
          if (canEditOngoingAssessment) {
            buttons.push(this.editAssessmentButton);
          } else {
            buttons.push(this.editAssessmentButtonDisabled);
          }
        } else {
          buttons.push(this.startAssessmentButton);
        }
      } else if (canControlAssessment) {
        if (hasOngoingAssessment) {
          buttons.push(this.controlAssessmentButton);
        }
      }

      if (canEditTypology && hasAffectations) {
        buttons.push(this.editTypologyButton);
      }

      if (itechEnabled && canAnnounceTechnicalInstallation) {
        buttons.push(this.announceTechnicalInstallationButton);
      }
    }

    if (buttons.length === 0) {
      this.fabService.clearFabOptions();
    } else {
      this.fabService.setFabOptions({buttons});
    }
  };

  navigateToBuilding(currentBuildingId: number, increment: number): Promise<boolean> { // TODO fix that
    const index = this.selectedBuildings.map(building => building.id).indexOf(currentBuildingId);
    if (index >= 0) {
      const nextBuildingId = this.selectedBuildings[index + increment].id;
      const nextUrl = this.router.url.replace(`${currentBuildingId}`, `${nextBuildingId}`);
      return this.router.navigate([nextUrl]);
    }

    return Promise.resolve(false);
  }

  async startControl() {
    const buildingId = await firstValueFrom(this.buildingId$);
    if (buildingId) {
      this.store.dispatch(startNewControl({buildingId}));
      this.dialogService.openDialogComponent<ControlDetails>(
        BatchControlEditDialogComponent,
        {
          count: 1,
        },
        'sibat-responsive-dialog'
      );
    }
  }

  async editControl() {
    const buildingId = await firstValueFrom(this.buildingId$);
    if (buildingId) {
      this.store.dispatch(refreshOngoingControl({buildingId}));
      this.dialogService.openDialogComponent(
        BatchControlEditDialogComponent,
        {
          count: 1,
        },
        'sibat-responsive-dialog'
      );
    }
  }

  async startAssessment() {
    const buildingId = await firstValueFrom(this.buildingId$);
    if (buildingId) {
      this.store.dispatch(startNewAssessment({buildingId}));
      this.dialogService.openDialogComponent<AssessmentDto>(
        BatchAssessmentEditDialogComponent,
        {
          count: 1,
        },
        'sibat-responsive-dialog'
      );
    }
  }

  async editAssessment() {
    const buildingId = await firstValueFrom(this.buildingId$);
    if (buildingId) {
      this.store.dispatch(refreshOngoingAssessment({buildingId}));
      this.dialogService.openDialogComponent(
        BatchAssessmentEditDialogComponent,
        {
          count: 1,
        },
        'sibat-responsive-dialog'
      );
    }
  }

  startEditingDefect(controlId: number) {
    this.store.dispatch(fetchControl({controlId}));
    this.dialogService.openDialogComponent(
      ControlDefectListComponent,
      {
        controlId,
      },
      'sibat-responsive-dialog'
    );
  }

  async selectLinkControlToBuildingModal() {
    const building = await firstValueFrom(this.building$);
    if (building) {
      this.dialogService.openDialogComponent(
        LinkControlToBuildingComponent,
        {
          buildingId: building.id,
        },
      );
    }
  }

  async editTypology() {
    const building = await firstValueFrom(this.building$);
    if (building) {
      const typology = await this.dialogService.openDialogComponentAsync<UpdateTypologyDto>(
        BatchTypologyEditDialogComponent,
        {
          count: 1,
          building,
        },
        'sibat-responsive-dialog'
      );
      if (typology) {
        this.store.dispatch(
          updateTypologies({
            buildingIds: [building.id],
            typology,
          })
        );
      }
    }
  }

  async announceTechnicalInstallation() {
    const buildingId = await firstValueFrom(this.buildingId$);
    const result = await this.dialogService.openDialogComponentAsync<AnnouncementForm>(
      AnnouncementFormDialogComponent,
      {},
      'sibat-responsive-dialog'
    );

    if (result) {
      this.store.dispatch(announceInstallation({form: result, buildingId}));
      await this.router.navigate(['itech'], {relativeTo: this.route});
    }
  }
}
