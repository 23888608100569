import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { previewControlReport } from 'src/app/control/store/control.action';
import { ControlEntry, TypologyEntry } from '../model/history.model';
import { selectHasPolicy } from './../../authentication/store/user.selector';
import { ControlReportInfo, CONTROLS_FOLDER_NAME } from './../../control/model/control.model';
import { selectCurrentBuildingId } from './../store/building.selector';

@Component({
  selector: 'building-timeline-entry',
  templateUrl: 'timeline-entry.component.html',
  styleUrls: ['timeline-entry.component.scss'],
})
export class TimelineEntryComponent {
  @Input() typology: TypologyEntry;
  @Input() date: string;

  canDisplayDefects$: Observable<boolean>;

  private controlEntry$ = new BehaviorSubject<ControlEntry | undefined>(undefined);
  private controlEntry: ControlEntry;

  constructor(private router: Router, private store: Store) {
    this.canDisplayDefects$ = combineLatest([
      this.controlEntry$,
      this.store.select(selectHasPolicy('control.defectButton.get')),
    ]).pipe(
      map(
        ([controlEntry, isReadOnly]) =>
          controlEntry !== undefined &&
          controlEntry.defectCount > 0 &&
          (!isReadOnly || controlEntry.isCompliant)
      )
    );
  }

  get control() {
    return this.controlEntry;
  }

  @Input()
  set control(value: ControlEntry) {
    this.controlEntry = value;
    this.controlEntry$.next(value);
  }

  displayControlDefects(controlId: number): void {
    this.router.navigate([], { queryParams: { compliance: controlId } });
  }

  async previewControlReport() {
    if (this.control.reportFileName) {
      const buildingId = await this.store
        .select(selectCurrentBuildingId)
        .pipe(take(1))
        .toPromise();
      if (buildingId) {
        const reportInfo: ControlReportInfo = {
          buildingId,
          relativePath: CONTROLS_FOLDER_NAME,
          fileName: this.control.reportFileName
        };
        this.store.dispatch(previewControlReport({ source: reportInfo }));
      }
    } else {
      this.store.dispatch(previewControlReport({ source: this.control.id }));
    }
  }
}
