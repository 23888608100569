import { map } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  BUILDING_DOCUMENT_ERROR_CODE,
  BuildingDocument,
  BuildingDocumentError,
  BuildingDocumentErrorCode,
  MAX_SIZE_DOC,
} from '../model/building-document.model';
import { Store } from '@ngrx/store';
import {
  changeDirectory,
  createBuildingSubFolder,
  deleteBuildingDocument,
  downloadBuildingDocument,
  moveDocument,
  uploadBuildingDocument,
} from '../store/document.action';
import {
  selectBuildingDocumentBrowsingData,
  selectBuildingDocuments,
  selectBuildingSharePointDynamicUrl,
  selectIsBrowsingRootFolder,
  selectIsEditableFolder
} from '../store/building.selector';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { selectHasPolicy, selectUser } from 'src/app/authentication/store/user.selector';
import { FolderNameInputComponent } from '../../file-browser/folder-name-input/folder-name-input.component';
import { BuildingReportUpload, UploadReportDialogComponent } from './upload-report-dialog/upload-report-dialog.component';
import { uploadControlReport } from 'src/app/control/store/control.action';
import { RenameFileDialogComponent } from '../../file-browser/rename-file-dialog/rename-file-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { breadcrumbPath } from '../../file-browser/file-browser/file-browser.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { Building } from '../model/building.model';

interface TargetBreadcrumb {
  index: number;
  crumbs: string[];
}

@Component({
  selector: 'sibat-building-documents-detail',
  templateUrl: './building-documents-detail.component.html',
  styleUrls: ['building-documents-detail.component.scss'],
})


export class BuildingDocumentsDetailComponent implements OnChanges {

  @Input() building?: Building;
  errors: BuildingDocumentError[] = [];
  errorCodes = BUILDING_DOCUMENT_ERROR_CODE;

  displayedColumns: string[] = ['node', 'actions'];
  dataSource$ = this.store.select(selectBuildingDocuments);
  browsingData$ = this.store.select(selectBuildingDocumentBrowsingData);
  crumbs$ = this.browsingData$.pipe(
    map( x => {
      const filtered = x.documentsPath.split('/').filter(Boolean);
      return [''].concat(filtered);
    })
  );
  dynamicUrlSharePoint$ = this.store.select(selectBuildingSharePointDynamicUrl);
  isBrowsingRootFolder$ = this.store.select(selectIsBrowsingRootFolder);
  isEditableFolder$ = this.store.select(selectIsEditableFolder);
  hasPolicySharepointFileManagementEcab$ = this.store.select(selectHasPolicy('sharepoint.file.managementecab'));

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private translocoService: TranslocoService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.store.dispatch(changeDirectory({ path: '' }));
  }

  async droppedOnFolder(event: CdkDragDrop<BuildingDocument, BuildingDocument, BuildingDocument>) {
    const currentPath = await firstValueFrom(this.browsingData$);
    const sourceInfo = event.item.data;
    const destInfo = event.container.data;

    if (!destInfo.isFolder) {
      return;
    }

    const fromPath = `${currentPath.documentsPath}/${sourceInfo.name}`;
    const toPath = `${currentPath.documentsPath}/${destInfo.name}/${sourceInfo.name}`;

    this.store.dispatch(moveDocument({ fromPath, toPath, isFolder: sourceInfo.isFolder }));
  }

  async droppedOnCrumb(event: CdkDragDrop<TargetBreadcrumb, BuildingDocument, BuildingDocument>) {
    const currentPath = await firstValueFrom(this.browsingData$);
    const sourceInfo = event.item.data;
    const destInfo = event.container.data;

    if (destInfo.index === 0 || destInfo.index === destInfo.crumbs.length - 1) {
      return;
    }

    const fromPath = `${currentPath.documentsPath}/${sourceInfo.name}`;
    const toPath = breadcrumbPath(destInfo.crumbs, destInfo.index) + `/${sourceInfo.name}`;

    this.store.dispatch(moveDocument({ fromPath, toPath, isFolder: sourceInfo.isFolder }));
  }

  onBreadcrumbClick(endIndex: number, crumbs: string[]) {
    const targetPath = breadcrumbPath(crumbs, endIndex);

    this.store.dispatch(changeDirectory({ path: targetPath }));
  }

  validateFile(file: File) {
    if (file.size > MAX_SIZE_DOC) {
      this.addError(BUILDING_DOCUMENT_ERROR_CODE.maxSizeExceeded, file.name);
    }
  }

  async handleUploadFile(buildingDocuments: FileList, dropLocation?: BuildingDocument): Promise<void> {
    if (!buildingDocuments || buildingDocuments.length === 0) {
      return;
    }
    const browsingData = await firstValueFrom(this.browsingData$);
    const couldUploadOldReport = '04_Controles' === browsingData.documentsPath;
    if (!couldUploadOldReport) {
      this.uploadFiles(buildingDocuments, dropLocation);
    } else {
      if (buildingDocuments.length > 1) {
        const shouldContinue = await this.dialogService.requestConfirmation(
          { translationKey: 'building.documents.validateMultipleFileUpload' },
          { translationKey: 'common.continue' },
          { translationKey: 'common.cancel' }
        );
        if (shouldContinue) {
          this.uploadFiles(buildingDocuments, dropLocation);
        }
      } else {
        return this.uploadControlReport(buildingDocuments, dropLocation);
      }
    }
  }

  uploadFiles(buildingDocuments: FileList, dropLocation?: BuildingDocument): void {
    this.errors = [];
    for (const file of Array.from(buildingDocuments)) {
      this.validateFile(file);
      if (this.errors.length === 0) {
        const dropLocationPath = (dropLocation && !dropLocation.isFolder ? null : dropLocation?.name) || '';
        this.store.dispatch(uploadBuildingDocument({ file, nestedFolder: dropLocationPath, overwrite: false }));
      }
    }
  }

  async uploadControlReport(buildingDocuments: FileList, dropLocation?: BuildingDocument): Promise<void> {
    const user = await firstValueFrom(this.store.select(selectUser));
    if (!user?.policies?.includes('control.old.add')) {
      console.warn('The user does not have the permission for this action');
      return;
    }
    const reportFile = buildingDocuments[0];
    this.errors = [];
    this.validateFile(reportFile);
    if (this.errors.length === 0 && this.building) {
      const dropLocationPath = (dropLocation && !dropLocation.isFolder ? null : dropLocation?.name) || '';
      const result = await this.dialogService.openDialogComponentAsync<BuildingReportUpload>(
        UploadReportDialogComponent,
        {
          file: reportFile,
        },
        'no-border-dialog-container'
      );
      if (result) {
        if (result.controlDate) {
          this.store.dispatch(
            uploadControlReport({
              buildingId: this.building.id,
              controlDate: result.controlDate,
              report: result.report,
              overwrite: false,
            })
          );
        } else {
          this.store.dispatch(uploadBuildingDocument({ file: result.report, nestedFolder: dropLocationPath, overwrite: false }));
        }
      }
    }
  }

  /* TODO UGLY FIX ME */
  addError(code: BuildingDocumentErrorCode, fileName: string) {
    this.errors = [...this.errors, { code, fileName }];
  }

  async onNodeClick(node: BuildingDocument) {
    if (node.isFolder) {
      const documentsInfo = await firstValueFrom(this.browsingData$);
      const path = [documentsInfo.documentsPath, node.name].filter(Boolean).join('/');
      this.store.dispatch(changeDirectory({ path }));
    } else {
      this.downloadFile(node.name);
    }
  }

  async onCreateSubFolderClick(): Promise<void> {
    const result = await this.dialogService.openDialogComponentAsync<string>(FolderNameInputComponent);
    if (result) {
      this.store.dispatch(createBuildingSubFolder({ folderName: result }));
    }
  }

  onNavigate(targetPath: string) {
    this.store.dispatch(changeDirectory({ path: targetPath }));
  }

  downloadFile(filename: string) {
    this.store.dispatch(downloadBuildingDocument({ filename }));
  }

  async confirmRemoveFile(file: BuildingDocument): Promise<void> {
    const label = this.translocoService.translate('building.documents.confirmDelete', { file: file.name });
    const result = await this.dialogService.requestConfirmation(label);
    if (result) {
      this.store.dispatch(deleteBuildingDocument({ fileName: file.name }));
    }
  }

  async renameFile(file: BuildingDocument): Promise<void> {
    const result = await this.dialogService.openDialogComponentAsync<string>(RenameFileDialogComponent, { fileName: file.name });
    if (result) {
      const currentPath = await firstValueFrom(this.browsingData$);
      const fromPath = `${currentPath.documentsPath}/${file.name}`;
      const toPath = `${currentPath.documentsPath}/${result}`;

      this.store.dispatch(moveDocument({ fromPath, toPath, isFolder: false }));
    }
  }

}
