<div *ngIf="section === 'titleMO'" class="info-section">
  <div class="info">
    <span class="label">{{ 'building.summary.municipality' | transloco }}</span>
    <p class="subline">{{ building.municipality }}</p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.assignment' | transloco }}</span>
    <p *ngIf="building.localizedAffectations?.length" class="subline">
      {{ building.localizedAffectations | join }}
    </p>
    <p *ngIf="!building.localizedAffectations?.length"
       class="subline">{{ 'building.affectationToDefine' | transloco }}</p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.buildingClass' | transloco }}</span>
    <p class="subline">{{ building.buildingClass }}</p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.description' | transloco }}</span>
    <p class="subline">{{ building.description }}</p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.constructionPeriod' | transloco }}</span>
    <p class="subline" *ngIf="building.period">
      {{ 'building.constructionPeriod' | transloco: {period: building.period} }}
    </p>
    <p *ngIf="!building.period">&nbsp;</p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.egid' | transloco }}</span>
    <p class="subline">{{ building.egid ?? ('common.notDefined' | transloco) }}</p>
  </div>
  <div class="info">
    <p class="label">
      {{ 'building.summary.complementaryInformations' | transloco }}
    </p>
  </div>
</div>
<div *ngIf="section === 'titleSibat'" class="info-section">
  <div class="info">
    <span class="label">{{ 'building.summary.id' | transloco }}</span>
    <p class="subline">{{ building.id }}</p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.typology' | transloco }}</span>
    <p class="subline">
      <span class="color" [ngStyle]="{ 'color': getTextColor(building.typology?.color) }">
        {{ building.typology?.color || 'N/A' }}
      </span>
    </p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.status' | transloco }}</span>
    <p class="subline">
    <span [ngSwitch]="building.status">
      <span *ngSwitchCase="buildingStatus.constructed" class="subline">{{ 'building.status.constructed' | transloco }}</span>
      <span *ngSwitchCase="buildingStatus.exploitation" class="subline">{{ 'building.status.exploitation' | transloco }}</span>
      <span *ngSwitchCase="buildingStatus.project" class="subline">{{ 'building.status.project' | transloco }}</span>
      <span *ngSwitchCase="buildingStatus.underConstruction" class="subline">{{ 'building.status.underConstruction' | transloco }}</span>
      <span *ngSwitchCase="buildingStatus.demolished" class="subline">{{ 'building.status.demolished' | transloco }}</span>
    </span>
    </p>
  </div>
  <div class="info">
    <span class="label">{{ 'building.summary.nextControlDate' | transloco }}</span>
    <p class="subline">
      {{ building.nextControlDate ? (building.nextControlDate | date: 'dd.MM.yyyy') : ('common.toBeDefined' | transloco) }}
      <mat-icon *ngIf="(canEditNextControlDate$ | async) === true" [title]="'common.edit' | transloco" (click)="editNextControlDate()">edit</mat-icon>
    </p>
  </div>
  <div class="info" *ngIf="!!building?.urlSharePoint">
    <span class="label">{{ 'building.detail.sharePointLink' | transloco }}</span>
    <span class="subline">
      <sibat-link-button
          [href]="building.urlSharePoint"
          [external]="true"
          [target]="'_blank'"
          [label]="'building.detail.goToSharePoint' | transloco"
      ></sibat-link-button>
    </span>
  </div>
</div>
