import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from '../../shared/service/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FolderNameInputComponent } from '../folder-name-input/folder-name-input.component';
import { RenameFileDialogComponent } from '../rename-file-dialog/rename-file-dialog.component';
import {
  MAX_SIZE_DOC,
  SIBAT_FILE_ERROR_CODE,
  SibatFile,
  SibatFileError,
  SibatFileErrorCode,
  TargetBreadcrumb,
  isFileAlreadyExistsError
} from '../model/sibat-file.model';

import { FileBrowserStore } from '../store/file-browser.store';
import { FileBrowserService } from '../service/file-browser.service';
import { notifyGenericError } from '../../notification/store/notification.action';
import { notifyWarn } from '../../model/notification.model';
import { saveAs } from 'file-saver';
import { FileAlreadyExistsDialogComponent, UploadOption } from '../duplicate-error-dialog/file-already-exists-dialog.component';
import { copyFileIncrement } from 'src/app/shared/files';
import { firstValueFrom } from 'rxjs';
import { selectHasPolicy} from '../../authentication/store/user.selector';


@Component({
  selector: 'sibat-file-browser',
  templateUrl: './file-browser.component.html',
  styleUrls: ['./file-browser.component.scss'],
  providers: [FileBrowserStore]
})
export class FileBrowserComponent implements OnChanges {

  @Input() root: number;
  @Input() sharePointUrl: string;
  @Input() caseType: string;

  errors: SibatFileError[] = [];
  errorCodes = SIBAT_FILE_ERROR_CODE;
  displayedColumns: string[] = ['node', 'actions'];

  dataSource$ = this.fileBrowserStore.files$;
  currentPath$ = this.fileBrowserStore.path$;
  crumbs$ = this.fileBrowserStore.crumbs$;
  dynamicUrl$ = this.fileBrowserStore.dynamicUrl$;
  hasPolicySharepointManagementEcab$ = this.store.select(selectHasPolicy('sharepoint.file.managementecab'));


  constructor(
    public fileBrowserStore: FileBrowserStore,
    private fileBrowserService: FileBrowserService,
    private store: Store,
    private dialogService: DialogService,
    private translocoService: TranslocoService) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.fileBrowserStore.setUrl(this.sharePointUrl);
    this.fileBrowserStore.setRoot(this.root);
    await this.changeDirectory('');
  }

  async changeDirectory(path: string): Promise<void> {
    const files = await firstValueFrom(this.fileBrowserService.getFiles(this.root, path));
    this.fileBrowserStore.setPathAndFiles({path, files});
  }

  async onNodeClick(node: SibatFile): Promise<void> {
    if (node.isFolder) {
      const rootPath = await firstValueFrom(this.currentPath$);
      const path = [rootPath, node.name].filter(Boolean).join('/');
      await this.changeDirectory(path);
    } else {
      await this.downloadFile(node.name);
    }
  }

  async onBreadcrumbClick(endIndex: number, crumbs: string[]): Promise<void> {
    const targetPath = breadcrumbPath(crumbs, endIndex);
    await this.changeDirectory(targetPath);
  }

  async renameFile(file: SibatFile): Promise<void> {
    const result = await this.dialogService.openDialogComponentAsync<string>(RenameFileDialogComponent, {fileName: file.name});
    if (result) {
      const currentPath = await firstValueFrom(this.currentPath$);
      const fromPath = currentPath ? `${currentPath}/${file.name}` : file.name;
      const toPath = currentPath ? `${currentPath}/${result}` : result;
      this.fileBrowserService.renameFile(this.root, fromPath, toPath).subscribe({
        next: () => {
          this.fileBrowserStore.renameFile({oldName: file.name, newName: result});
        },
        error: (error) => {
          this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
        }
      });
    }
  }

  async droppedOnFolder(event: CdkDragDrop<SibatFile, SibatFile, SibatFile>): Promise<void> {
    const currentPath = await firstValueFrom(this.currentPath$);
    const sourceInfo = event.item.data;
    const destInfo = event.container.data;
    if (!destInfo.isFolder) {
      return;
    }

    const fromPath = `${currentPath}/${sourceInfo.name}`;
    const toPath = `${currentPath}/${destInfo.name}/${sourceInfo.name}`;

    this.fileBrowserService.moveFile(this.root, fromPath, toPath, sourceInfo.isFolder).subscribe({
      next: () => {
        this.fileBrowserStore.removeFromList(sourceInfo.name);
        this.fileBrowserStore.markNotEmpty(destInfo.name);
      },
      error: (error) => {
        this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
      }
    });
  }

  async droppedOnCrumb(event: CdkDragDrop<TargetBreadcrumb, SibatFile, SibatFile>): Promise<void> {
    const currentPath = await firstValueFrom(this.currentPath$);
    const sourceInfo = event.item.data;
    const destInfo = event.container.data;

    if (destInfo.index === destInfo.crumbs.length - 1) {
      return;
    }

    const fromPath = `${currentPath}/${sourceInfo.name}`;
    const destinationPath = breadcrumbPath(destInfo.crumbs, destInfo.index);
    const toPath = (destinationPath ? `${destinationPath}/` : '') + sourceInfo.name;

    this.fileBrowserService.moveFile(this.root, fromPath, toPath, sourceInfo.isFolder).subscribe({
      next: () => {
        this.fileBrowserStore.removeFromList(sourceInfo.name);
      },
      error: (error) => {
        this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
      }
    });
  }

  async createFolder(): Promise<void> {
    const result = await this.dialogService.openDialogComponentAsync<string>(FolderNameInputComponent);
    if (result) {
      const path = await firstValueFrom(this.currentPath$);
      this.fileBrowserService.createFolder(this.root, path, result).subscribe({
        next: () => {
          this.fileBrowserStore.addFolderToList(result);
        },
        error: (error) => {
          this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
        }
      });
    }
  }

  async removeFile(file: SibatFile): Promise<void> {
    const label = this.translocoService.translate('building.documents.confirmDelete', {file: file.name});
    const result = await this.dialogService.requestConfirmation(label);
    if (result) {
      const path = await firstValueFrom(this.currentPath$);
      this.fileBrowserService.deleteFile(this.root, path, file.name).subscribe({
        next: () => {
          this.fileBrowserStore.removeFromList(file.name);
        },
        error: (error) => {
          this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
        }
      });
    }
  }

  async downloadFile(fileName: string): Promise<void> {
    const path = await firstValueFrom(this.currentPath$);
    this.fileBrowserService.downloadFile(this.root, path, fileName).subscribe({
      next: (content) => {
        const blob = new Blob([content], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
      },
      error: (error) => {
        this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
      }
    });
  }

  async handleUploadFile(
    buildingDocuments: FileList,
    dropLocation?: SibatFile
  ): Promise<void> {
    if (!buildingDocuments || buildingDocuments.length === 0) {
      return;
    }
    await this.uploadFiles(buildingDocuments, dropLocation);
  }

  async uploadFiles(uploadedFiles: FileList, dropLocation?: SibatFile): Promise<void> {
    this.errors = [];
    const path = await firstValueFrom(this.currentPath$);
    for (const file of Array.from(uploadedFiles)) {
      this.validateFile(file);
      if (this.errors.length === 0) {
        const dropLocationPath = (dropLocation && !dropLocation.isFolder ? null : dropLocation?.name) || '';
        await this.uploadFile(path, file, dropLocationPath);
      }
    }
  }

  async uploadFile(path: string, file: File, dropLocation: string, overwrite: boolean = false): Promise<void> {
    try {
      await firstValueFrom(this.fileBrowserService.uploadFile(this.root, path, dropLocation, file, overwrite));
      this.fileBrowserStore.addFileToList(file.name);
    } catch (error) {
      if (isFileAlreadyExistsError(error.error.message)) {
        const uploadOption = await this.dialogService
          .openDialogComponentAsync<UploadOption>(FileAlreadyExistsDialogComponent, {
            fileName: file.name,
          });
        if (uploadOption === 'overwrite') {
          return this.uploadFile(path, file, dropLocation, true);
        } else if (uploadOption === 'keepBoth') {
          const renamedFile = copyFileIncrement(file);
          return this.uploadFile(path, renamedFile, dropLocation, false);
        }

        // cancelled
        return;
      }
      this.store.dispatch(notifyGenericError({ error, ...notifyWarn('building.error.unexpected', true) }));
    }
  }
  isControlCase(): boolean {
    return this.caseType === 'CONTROL';
  }

  validateFile(file: File): void {
    if (file.size > MAX_SIZE_DOC) {
      this.addError(SIBAT_FILE_ERROR_CODE.maxSizeExceeded, file.name);
    }
  }

  addError(code: SibatFileErrorCode, fileName: string): void {
    this.errors = [...this.errors, {code, fileName}];
  }

}

export const breadcrumbPath = (crumbs: string[], endIndex: number): string =>
  crumbs.slice(0, endIndex + 1).filter(Boolean).join('/');
