import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectHasPolicy } from '../authentication/store/user.selector';
import { ControlDetails } from '../control/model/control.model';
import { refreshOrphanControl, startOrphanControl } from '../control/store/control.action';
import { selectAreOrphanControlsEnabled } from '../features/store/feature-flags.selector';
import {
  BatchControlEditDialogComponent
} from '../building/batch-control-edit-dialog/batch-control-edit-dialog.component';
import { DialogService } from '../shared/service/dialog.service';
import { fetchAllRoles } from '../admin/store/admin.action';
import { CockpitService } from './cockpit.service';

@Component({
  selector: 'sibat-cockpit',
  template: `
    <ng-container>
      <div class="widget-container">
        <div class="widget-column">
          <sibat-user-widget></sibat-user-widget>
          <sibat-account-management-widget
            *ngIf="hasPolicyCockpitManagementGet$ | async"></sibat-account-management-widget>
          <sibat-municipality-widget *ngIf="hasPolicyCockpitManagementGet$ | async"></sibat-municipality-widget>
          <sibat-feature-flags-activator-widget
            *ngIf="hasPolicyCockpitManagementGet$ | async"></sibat-feature-flags-activator-widget>
          <sibat-tags-widget></sibat-tags-widget>
        </div>
        <div class="widget-column">
          <sibat-motd-widget *ngIf="hasPolicyCockpitManagementGet$ | async"></sibat-motd-widget>
          <sibat-orphan-controls
            *ngIf="(orphanControlsEnabled$ | async) && (hasPolicyCockpitNoBuildingControlWidgetGet$ | async)"
            (orphanControlStarted)="startOrphanControl()"
            (editOrphanControl)="editOrphanControl($event)"
          ></sibat-orphan-controls>
          <sibat-control-dashboard-widget *ngIf="hasPolicyControlWidgetGet$ | async"></sibat-control-dashboard-widget>
        </div>
      </div>
      <div class="widget-row">
        <sibat-non-compliant-defects-widget *ngIf="hasPolicyCockpitNoBuildingControlWidgetGet$ | async"
                                            data-testid="defects"></sibat-non-compliant-defects-widget>
        <div class="button-container" *ngIf="hasPolicyCockpitManagementGet$ | async">
          <button mat-flat-button color="primary"
                  (click)="populateBuildingContextObjectTable('Bat', 'layer_context_1')">
            Remplir la table BuildingContextObject
          </button>
          <button mat-flat-button color="primary" (click)="populateCaseContextObjectTable('Aff', 'layer_context_1')">
            Remplir la table CaseContextObject
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['cockpit.component.scss'],
})
export class CockpitComponent implements OnInit {
  orphanControlsEnabled$ = this.store.select(selectAreOrphanControlsEnabled);
  hasPolicyCockpitManagementGet$ = this.store.select(selectHasPolicy('cockpit.management.get'));
  hasPolicyControlWidgetGet$ = this.store.select(selectHasPolicy('cockpit.controlWidget.get'));
  hasPolicyCockpitNoBuildingControlWidgetGet$ = this.store.select(selectHasPolicy('cockpit.noBuildingControlWidget.get'));

  constructor(private store: Store, private dialogService: DialogService, private cockpitService: CockpitService) {
  }

  ngOnInit(): void {
    this.store.dispatch(fetchAllRoles());
  }

  startOrphanControl() {
    this.store.dispatch(startOrphanControl());
    this.openControlModal();
  }

  editOrphanControl(controlId: number) {
    this.store.dispatch(refreshOrphanControl({controlId}));
    this.openControlModal();
  }

  openControlModal() {
    this.dialogService.openDialogComponent<ControlDetails>(
      BatchControlEditDialogComponent,
      {
        count: 1
      },
      'sibat-responsive-dialog'
    );
  }

  populateBuildingContextObjectTable(entity: string, context: string) {
    const requestObject = {entityType: entity, contextCode: context};
    this.cockpitService.sendObjectContext(requestObject).subscribe(
      response => {
        console.log('Server response:', response);
      },
      error => {
        console.error('Error while populating contexts:', error);
      }
    );
  }

  populateCaseContextObjectTable(entity: string, context: string) {
    const requestObject = {entityType: entity, contextCode: context};
    this.cockpitService.sendObjectContext(requestObject).subscribe(
      response => {
        console.log('Server response:', response);
      },
      error => {
        console.error('Error while populating contexts:', error);
      }
    );
  }
}
