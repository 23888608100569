import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Building } from '../model/building.model';
import { AssessmentDto } from '../../assessment/model/assessment.model';
import { selectBuilding } from '../store/building.selector';
import { selectOngoingAssessment } from '../../assessment/store/assessment.selector';
import { selectHasPolicy } from '../../authentication/store/user.selector';

@Component({
  selector: 'sibat-batch-assessment-edit-dialog',
  templateUrl: './batch-assessment-edit-dialog.component.html',
})
export class BatchAssessmentEditDialogComponent {
  building$ = this.store.select(selectBuilding);
  assessment$ = this.store.select(selectOngoingAssessment);
  hasCECBPolicy$ = this.store.select(selectHasPolicy('assessment.batch.get'));

  readonly building?: Building;
  readonly count: number;

  constructor(
    private dialogRef: MatDialogRef<BatchAssessmentEditDialogComponent, AssessmentDto>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private data: { count: number }
  ) {
    this.count = this.data.count ?? 1;
  }

  close() {
    this.dialogRef.close();
  }
}
