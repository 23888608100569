import { CasePreview } from 'src/app/case/model/case.model';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectHasPolicy } from 'src/app/authentication/store/user.selector';
import { fetchBuildingCase } from 'src/app/building/store/building-case.action';
import { mergeMap, tap } from 'rxjs/operators';
import { selectCasePreviews } from 'src/app/building/store/building.selector';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'sibat-case-preview',
  templateUrl: './case-preview.component.html',
  styleUrls: ['./case-preview.component.scss'],
})
export class CasePreviewComponent {
  @Input() casePreviews: CasePreview[];
  casePreviews$ = combineLatest([
    this.store.select(selectHasPolicy('case.previewDetails.get')),
    (this.route.parent ?? this.route).params,
  ]).pipe(
    tap(([hasPolicy, { buildingId }]) => {
      if (hasPolicy && this.casePreviews === undefined) {
        this.store.dispatch(fetchBuildingCase({ buildingId }));
      }
    }),
    mergeMap(() => {
      if (this.casePreviews !== undefined) {
        return of(this.casePreviews);
      }
      return this.store.select(selectCasePreviews);
    })
  );

  constructor(private store: Store, private route: ActivatedRoute) {}
}
