import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { APPROVAL_STATE, ApprovalState } from '../../model/approval-state.model';
import { approvalStatus, Control } from '../../model/control.model';

@Component({
  selector: 'sibat-control-approval-button',
  template: `
    <section *transloco="let t; read: 'building.control'" fxLayout="column">
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.canBeValidated || approvalState === approvalStates.expertHasApprovedControl"
        (clicked)="triggerValidateControl()"
        [label]="t('validateControl')"
        [disabled]="isDisabled"
        [stretch]="true"
      >
      </sibat-primary-button>
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.expertShouldApproveControl"
        (clicked)="triggerApproveControl()"
        [label]="t('approveControl')"
        [disabled]="isDisabled"
        [stretch]="true"
      >
      </sibat-primary-button>
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.scpiRequireExpertApproval"
        (clicked)="triggerRequestApproval()"
        [label]="t('requestExpertApproval')"
        [disabled]="isDisabled"
        [stretch]="true"
      >
      </sibat-primary-button>
      <sibat-primary-button
        *ngIf="approvalState === approvalStates.scpiAwaitingExpertApproval"
        (clicked)="triggerCancelRequestApproval()"
        [label]="t('cancelRequestExpertApproval')"
        [disabled]="isDisabled"
        [stretch]="true"
      >
      </sibat-primary-button>
    </section>
  `,
  styleUrls: [],
})
export class ControlApprovalButtonComponent implements OnChanges {
  @Output() validateControl = new EventEmitter<void>();
  @Output() requestApproval = new EventEmitter<void>();
  @Output() cancelRequestApproval = new EventEmitter<void>();
  @Output() approveControl = new EventEmitter<void>();
  @Input() control: Control;
  @Input() isDisabled: boolean;
  @Input() hasControlBatchGetPolicy: boolean;
  approvalStates = APPROVAL_STATE;
  approvalState?: ApprovalState;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control) {
      this.updateApprovalState();
    }
  }

  triggerValidateControl() {
    this.validateControl.emit();
  }

  triggerRequestApproval() {
    this.requestApproval.emit();
  }

  triggerCancelRequestApproval() {
    this.cancelRequestApproval.emit();
  }

  triggerApproveControl() {
    this.approveControl.emit();
  }

  updateApprovalState() {
    this.approvalState = !!this.control ? approvalStatus(this.control, this.hasControlBatchGetPolicy) : undefined;
  }
}
